import { Link } from "react-router-dom"
import { KTSVG } from "../../../../_metronic/helpers"

type Props = {
  totalClients?: number
  className?: string
  description?: string
  color?: string
  img?: string
}

const TotalClientsCard = ({ className, description, color, img, totalClients }: Props) => {
  return (
    <Link
      to='/client-management/clients'
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-3hx fw-bold text-white me-2 lh-1 ls-n2'>{totalClients || 0}</span>

          <span className='text-white opacity-75 pt-1 fw-semibold fs-2'>{description}</span>
        </div>
      </div>
      <div className='card-body d-flex align-items-end pt-0'>
        <div className='d-flex align-items-end flex-column mt-3 w-100'>
          {/* <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
            <span>43 Pending</span>
            <span>72%</span>
          </div>

          <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
            <div
              className='bg-white rounded h-8px'
              role='progressbar'
              style={{ width: '72%' }}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div> */}
          {/* rotate it */}
          <div className='d-flex text-white gap-3 align-items-center'>
            View All
            <div style={{ transform: 'rotate(45deg)' }}>
              <KTSVG
                path='/media/icons/duotune/arrows/arr066.svg'
                className='svg-icon-1 svg-icon-white mt-2 rotate-45'
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export { TotalClientsCard }
