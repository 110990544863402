/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {routeMap} from '../../../../../app/routing/routes'
import {useAuth} from '../../../../../app/modules/auth'
const SidebarMenuMain = () => {
  const intl = useIntl()
  const {isAdminRole, clientId} = useAuth()

  return (
    <>
      {isAdminRole && (
        <>
          <SidebarMenuItem
            to={routeMap.dashboard}
            icon='/media/icons/duotune/art/art002.svg'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-app-indicator'
          />

          <SidebarMenuItem
            title='All Clients'
            to={routeMap.clients}
            icon='/media/icons/duotune/communication/com006.svg'
          />
          {/* <SidebarMenuItem
            title='Payments'
            to={routeMap.payments}
            icon='/media/icons/duotune/general/gen048.svg'
          /> */}
        </>
      )}
      {!isAdminRole && (
        <>
          <SidebarMenuItem
            title='View Details'
            to={`${routeMap.clients}/${clientId}`}
            icon='/media/icons/duotune/communication/com006.svg'
          />
          <SidebarMenuItemWithSub
            title='Reports'
            to=''
            fontIcon='/media/icons/duotune/general/gen005.svg'
            icon='/media/icons/duotune/general/gen005.svg'
          >
            <SidebarMenuItem
              title='All Unpaid Transactions'
              to={`${routeMap.unPaidTransaction}/${clientId}`}
            />
            <SidebarMenuItem
              title='All Upcoming Payments'
              to={`${routeMap.upcomingPayments}${clientId}`}
            />
          </SidebarMenuItemWithSub>
        </>
      )}
    </>
  )
}

export {SidebarMenuMain}
