export const routeMap = {
  auth: '/auth',
  dashboard: '/dashboard',
  allClients: '/clients',
  clients: '/client-management/clients',
  payments: '/payments',
  unPaidTransaction: '/unPaidTransaction',
  upcomingPayments: '/upcomingPayments',
  error: '/error/404',
}
